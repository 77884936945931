import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import VueCurrencyFilter from 'vue-currency-filter' // currency filter
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
const toastOptions = {
    // You can set your default options here
}

Vue.config.performance = true
Vue.use(VueCurrencyFilter, { symbol: '$', thousandsSeparator: ',', fractionCount: 2, fractionSeparator: '.', symbolPosition: 'front', symbolSpacing: false })
Vue.use(CoreuiVue)
Vue.use(Toast, toastOptions)
Vue.prototype.$log = console.log.bind(console)

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
